function Previous() {
    return (
        <div className="flex flex-col gap-1 py-3 px-1">
            <h1 className="text-decoration-line: underline">Current Position</h1>
            <div className="flex flex-col gap-1 text-sm lg:text-[12px] py-1">
                {/* <h1>Salary: <strong>U$D 4500</strong></h1> */}
                <h1>Role: <strong>Software Engineer @ Expensify</strong></h1>
                <h1>Location: Remote </h1>
                {/* <h1>Duration: 22 months</h1> */}
                <h1>Company: <a href="https://www.expensify.com/">Expensify</a></h1>
                {/* <h1>Company Size: ~20 employees (4 engineers)</h1> */}
            </div>
        </div>
    )
}

export default Previous